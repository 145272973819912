import React from "react";
import tw from "twin.macro";
import Header from "./header";


export default ({ children, setHtmlAttributes, id }) => (
  <div tw="antialiased leading-tight min-h-screen w-full bg-white">
    <Header/>
    <div tw="md:max-w-screen-md mx-auto">
      <main id={id} tw="mt-4 md:mt-0 mx-2 mb-24">
        {children}
      </main>
    </div>
  </div>
);
