import React from "react";
import {Link} from "gatsby";
import tw from "twin.macro";

export default ({to, children}) => (
  <Link to={to}>
    <span tw="inline bg-blue-ocean text-sm text-white px-2 py-1 font-semibold mr-2">
      #{children}
    </span>
  </Link>
)
