import React from "react";
import tw from "twin.macro";
import {Link} from "gatsby";

const NavLink = ({to, children}) => (
  <Link tw="text-blue-ocean no-underline uppercase tracking-wide font-bold text-xl sm:mx-4 mx-auto" to={to}>
    {children}
  </Link>
);

export default () => (
  <header tw="md:max-w-screen-md mx-auto my-5">
    <nav tw="flex justify-end">
      <NavLink to="/">Blogs</NavLink>
      <NavLink to="/tags">Tags</NavLink>
      <NavLink to="/about">About</NavLink>
    </nav>
  </header>
)
